<template>
  <div class="main-container">
      <div class="pt-wrapper">
        <!-- 搜索 -->
        <div class="hd-search">
          <table-search
            :model="searchParams"
            label-width="80px"
            @search="searchFn('search')"
            :flex="'4'"
            :isResetByPublic="false"
            @clearSearch="clearSearchFn"
          >
            <search-item default label="">
              <el-input
                size="small"
                v-model="searchParams.dicName"
                placeholder="代码名称11"
                clearable
              />
            </search-item>
          </table-search>
        </div>
        <div class="bd-content">
          <!-- 操作按钮 -->
          <div class="table-opt">
            <el-button
              type="primary"
              class="el-icon-circle-plus-outline"
              @click="addDict('1')"
            >
              新 增
            </el-button>
          </div>
          <!-- 表格 -->
          <pageTable
            ref="table"
            :columns="columnList"
            :params="searchParams"
            :api-function="this.$api.sysManage.queryCategoryList"
          >
            <template slot="options" slot-scope="scope">
              <div class="data-operate">
                <span class="op-blue" @click="goDetail('edit', scope.row)">
                  配置字典
                </span>
                <span class="op-blue" @click="addDict('2', scope.row)">
                  编辑
                </span>
                <span class="op-red" @click="del(scope.row.id)"> 删除 </span>
              </div>
            </template>
          </pageTable>
        </div>
      </div>
    <add-alert
      :dialogVisible.sync="dialogVisible"
      :dialog-type="dialogType"
      @update="searchFn"
      :formData="editData"
      v-if="dialogVisible"
    />
  </div>
</template>

<script>
import addAlert from './component/dictComponent/alertAction.vue';

export default {
  name: 'dictDetail',
  components: {
    addAlert,
  },
  data() {
    return {
      columnList: [
        {
          label: '序号',
          type: 'index',
          width: window.innerWidth >= 1920 ? 80 : 60,
        },
        {
          label: '代码名称',
          prop: 'dicName',
        },
        {
          label: '操作',
          prop: 'options',
          show: 'template',
          width: window.innerWidth >= 1920 ? 300 : 200,
        },
      ],
      searchParams: {
        dicPcode: '-1',
        dicName: '',
      },
      dialogType: 1,
      dialogVisible: false,
      editData: {},
    };
  },
  created() {},
  methods: {
    clearSearchFn() {
      this.searchParams = {
        dicPcode: '-1',
        dicName: '',
      };
      this.$nextTick(() => {
        this.searchFn('search');
      });
    },
    searchFn(status) {
      this.$refs.table.getPageDataOfAction(status);
    },
    del(id) {
      this.$msg.confirm('确认删除字典？').then(() => {
        this.$api.sysManage
          .removeDict({
            id: id,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.searchFn('del');
            }
          });
      });
    },
    addDict(type, data) {
      if (type == '2') {
        this.editData.dicName = data.dicName;
        this.editData.id = data.id;
      }
      this.dialogType = type;
      this.dialogVisible = true;
    },
    // 修改和详情去表单页
    goDetail(type, data) {
      this.$router.push({
        path: 'dictNextManage',
        query: {
          id: data.id,
          dicCategory: data.dicName,
          jb: data.jb,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  height: 100%;
  overflow-y: auto;
}
</style>
