<template>
  <!-- 1新增字典 2编辑字典 3新增字典next 4编辑字典next -->
  <el-dialog
    :title="
      dialogType == '1'
        ? '新增字典'
        : dialogType == '2'
        ? '编辑字典'
        : dialogType == '3'
        ? '新增下级'
        : '编辑下级'
    "
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    @close="$emit('update:dialogVisible', false)"
    width="40%"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        size="small"
        :rules="rules"
        :label-position="'right'"
        label-width="auto"
      >
        <el-form-item
          label="上级字典"
          prop="dicCategory"
          v-if="dialogType == '3' || dialogType == '4'"
        >
          <el-input v-model="form.dicCategory" clearable disabled></el-input>
        </el-form-item>
        <el-form-item label="字典名称" prop="dicName">
          <el-input v-model="form.dicName" clearable></el-input>
        </el-form-item>
        <el-form-item
          label="字典代码"
          prop="dicCode"
          clearable
          v-if="dialogType == '3' || dialogType == '4'"
        >
          <el-input v-model="form.dicCode"></el-input>
        </el-form-item>
        <el-form-item label="字典级别">
          <el-input v-model="form.jb" disabled></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:dialogVisible', false)"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="saveFn">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'alertAction',
  props: {
    dialogVisible: {
      type: Boolean,
      require,
    },
    dialogType: {
      type: String,
      require,
    },
    formData: {
      type: Object,
      require,
    },
  },
  data() {
    return {
      dialogFormVisible: this.dialogVisible,
      roleList: [],
      form: {
        dicCategory: '', //上级字典 第一层传它自己 中文
        dicName: '', //字典名 都有
        dicCode: '', //第一层填空 其他层手动填
        dicPcode: '-1', //上级的code  第一层传-1
        jb: 1, //级别 1 2 3 4 5 第一层写1 其他级别父级的jb+1
        id: '', //编辑的话有id
      },
      rules: {
        dicName: [{ required: true, message: '请输入字典名称' }],
        dicCode: [{ required: true, message: '请输入字典code' }],
      },
    };
  },
  methods: {
    saveFn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let url =
            this.dialogType == '1' || this.dialogType == '3'
              ? 'addDict'
              : 'updateDict';
          //第一级的新增编辑 category就是它自己
          if (this.dialogType == '1' || this.dialogType == '2') {
            this.form.dicCategory = this.form.dicName;
          }
          //所有的新增去掉传id
          if (this.dialogType == '1' || this.dialogType == '3') {
            delete this.form.id;
          }

          this.$api.sysManage[url](this.form)
            .then((res) => {
              if (res.code === 0) {
                this.$message.success(res.msg);
                this.$emit('update:dialogVisible', false);
                // 系统管理- 字典管理 --新增/编辑字典-弹窗关闭时列表查询 传参修改
                var flag = '';
                if (this.dialogType === '1' || this.dialogType === '3') {
                  flag = 'search';
                } else {
                  flag = 'edit';
                }
                this.$emit('update', flag);
                //如果是3 4的话还要刷新树
                if (this.dialogType == '3' || this.dialogType == '4') {
                  this.$emit('updatetree');
                }
              }
            })
            .catch(() => {
              this.$emit('update:dialogVisible', false);
            });
        }
      });
    },
  },
  mounted() {
    //只有第一层的新增不需要传数据
    if (this.dialogType !== '1') {
      this.form = Object.assign({}, this.form, this.formData);
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  width: 90%;
  margin: 0 auto;
}
</style>
